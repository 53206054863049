import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Navbar } from 'react-bootstrap';
import SplitterLayout from 'react-splitter-layout';
import { AceEditor, GraphRenderer, SessionButtons } from '../../Components';
import { GraphState } from '../../States';
import 'react-splitter-layout/lib/index.css';

const NewPageView = observer(
    class NewPage extends Component {

        render() {
              return (
                <>
                  <Navbar bg="dark" variant="dark">
                    <Navbar.Brand href="/graphs">DOT</Navbar.Brand>
                    <div className="collapse navbar-collapse justify-content-end">
                      <SessionButtons 
                        appState={this.props.appState}
                        graphState={this.props.graphState}
                      />
                    </div>
                  </Navbar>
                  <SplitterLayout vertical={ false } primaryIndex={ 0 } customClassName="position-fixed">
                     <GraphRenderer graphState={ this.props.graphState } />
                     <AceEditor onChange={ (newValue) => setTimeout ( () => this.props.graphState.setCode(newValue) , 100 ) } defaultValue={ this.props.graphState.code } />
                  </SplitterLayout>
                </>
                );
    }
});

export default (props) => (<NewPageView {...props} graphState={props.graphState || new GraphState(props.db)} />);
