import React, { Component } from 'react'
import Editor from 'react-ace';
import "ace-builds/src-noconflict/mode-dot";
import "ace-builds/src-noconflict/theme-monokai";

const defaultProps = {
    name: 'Testing',
    mode: 'dot',
    theme: 'monokai',
    editorProps: {$blockScrolling: true  },
    height: '100%',
    width: '100%'
};

class AceEditor extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    componentDidMount() {
        setTimeout(
            () => window.dispatchEvent(new Event('resize'))
        );
    }

    render() {
        return (
            <Editor {...defaultProps } {...this.props} />
        );
    }
};

export default AceEditor;
