import { makeAutoObservable } from 'mobx';

class App {
    user = null;
    showLoginModal = false;

    constructor(firebase) {
        makeAutoObservable(this)
        this.firebase = firebase;
    }

    login(user) {
        this.user = user;
    }

    openLoginModal() {
        this.showLoginModal = true;
    }

    closeLoginModal() {
        this.showLoginModal = false;
    }

    logout() {
        this.firebase.auth().signOut();
        this.user = null;
    }
}

export default App;
