import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

const MermaidRenderer = ({ graph, id, ...options }) => {
    const refContainer = useRef(null);

    const updateMermaidSvg = (svgCode) => {
        refContainer.current.innerHTML = svgCode;
        let svgEl = refContainer.current.firstElementChild;
        svgEl.style.maxWidth = '100%';
        svgEl.setAttribute('height', '80vh');
        if (options && options.options && options.options.height) {
            svgEl.setAttribute('height', options.options.height);
        }
    };

    useEffect(() => {
        mermaid.mermaidAPI.initialize({
            startOnLoad:false
        });
        mermaid.mermaidAPI.render(id, graph, updateMermaidSvg);
    });

    return (
        <div className="mermaid" ref={refContainer}></div>
    );
}; 

export default MermaidRenderer;
