import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from "react-router-dom";
import { Loading } from '../../Components';
import { Models } from '../../Models';
import { Card, Button, Navbar } from 'react-bootstrap';
import { GraphRenderer } from '../../Components';
import { GraphState } from '../../States';

const GraphsList = ({ graphs, onDelete }) => {
    const history = useHistory();

    const openGraph = (graphId) => {
        history.push(`/graphs/${graphId}`);
    };

    const deleteGraph = (graph) => {
        onDelete(graph);
    };

    return (
        <div className="container my-5">
            <div className="row">
            {
                graphs.map((graph) => {
                    return (
                        <div key={graph.id} className="col-md-4 p-2">
                        <Card>
                            <GraphRenderer
                                className="d-inline"
                                graphState={ graph }
                                options={{height: 200, width: '100%', zoom: false, useSharedWorker: true}}
                            />
                            <Card.Body>
                                <Card.Title>{graph.name}</Card.Title>
                            </Card.Body>
                             <Card.Body>
                                <Button onClick={() => openGraph(graph.id)}>Open</Button>
                                <Button className="ml-2" onClick={() => deleteGraph(graph)}>Delete</Button>
                             </Card.Body>
                        </Card>
                        </div>
                    );
                })
            }
            </div>
        </div>
    );
};

const NavbarButtons = ({ appState }) => {
    const history = useHistory();

    if (appState.user) {
        return (
            <>
                <Button variant="primary mr-2" onClick={ () => history.replace('/') }>New</Button>
                <Button variant="primary" onClick={ () => { appState.logout(); history.replace('/'); } }>Logout</Button>
            </>
        );
    }
    return (
        <>
            <Button variant="primary mr-2" onClick={ () => history.replace('/') }>New</Button>
            <Button variant="primary mr-1" onClick={ () => appState.openLoginModal() } >Login</Button>
        </>
    );
};

const ListPage = observer(
    class ListPage extends Component {

        constructor(props) {
            super(props);
            this.state = {
                loading: true,
                isLoaded: false,
                graphs: []
            };
            this.models = Models(props.db);
        }

        componentDidMount() {
            if (!localStorage.getItem('expectSignIn')) {
                this.props.history.replace('/');
            }
            let userId = this.getUserUid();
            this.loadGraphs(userId);
        }

        componentDidUpdate() {
            let userId = this.getUserUid();
            if ( this.state.isLoaded === true ) {
                return;
            }
            this.loadGraphs(userId);
        }

        async loadGraphs(userId) {
            if (!userId) {
                return;
            }
            try {
                let projects = await this.models.Graph.where('userId', '==', userId).get();
                projects = projects.map((project) => {
                    let graphState = new GraphState(this.props.db);
                    graphState.setCode(project.code);
                    graphState.setId(project.id);
                    graphState.setName(project.name);
                    return graphState;
                });
                this.setState({graphs: projects, loading: false, isLoaded: true});
            } catch(Error) {
                console.log(Error);
                this.props.history.replace('/');
            }
        }

        getUserUid() {
            if (!this.props.appState.user) {
                return null;
            }
            return this.props.appState.user.uid;
        }

        deleteGraph(graph) {
            let confirmation = window.confirm('Are you sure you want to delete the graph?');
            if (!confirmation) {
                return;
            }
            let graphs = this.state.graphs.filter(graphObj => graph != graphObj);
            this.setState({ graphs: graphs });
            graph.deleteGraph();
        }

        render() {
            if (this.state.loading === true) {
                return (<Loading user-uid={this.getUserUid()} />);
            }
            return (
                <>
                  <Navbar bg="dark" variant="dark">
                    <Navbar.Brand href="/graphs">DOT</Navbar.Brand>
                    <div className="collapse navbar-collapse justify-content-end">
                        <NavbarButtons appState={this.props.appState}/>
                    </div>
                  </Navbar>
                  <GraphsList graphs={this.state.graphs} onDelete={ (graph) => this.deleteGraph(graph) } />
                </>
            );
        }
    }
);

export default (props) => (<ListPage {...props} history={useHistory()} />);
