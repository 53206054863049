import RedPanda from 'redpanda-odm';
import Schema from './schema';

export default (firestoreInstance) => {
    if (!firestoreInstance) {
        return;
    }
    RedPanda.connect(firestoreInstance);
    let { GraphDocument } = Schema(RedPanda);

    class Graph extends GraphDocument {}

    return {
        Graph
    };
};
