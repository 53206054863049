import React  from 'react';
import { GraphvizRenderer, MermaidRenderer } from '../';

const GraphRenderer = ({ graphState, ...options }) => {

    if (graphState.isGraphviz() === true) {
        return (
            <GraphvizRenderer id={ graphState.id || 'new-graphviz' } className="d-inline" dot={ graphState.code } onError={ console.log } { ...options } />
        );
    }

    if (graphState.isMermaid() === true) {
        return (
            <MermaidRenderer id={ graphState.id || 'new-mermaid' } graph={ graphState.code } { ...options } />
        );
    }

    return (<></>);
};

 export default GraphRenderer;
