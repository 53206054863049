import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { observer } from 'mobx-react';
import { Button, Form } from 'react-bootstrap';

const SessionButtons = observer(({ appState, graphState }) => {
    const [validated, setValidated] = useState(false);
    const history = useHistory();

    const saveGraph = async () => {
        try {
            if (graphState.id && graphState.userId === appState.user.uid) {
                graphState.update();
            } else {
                await graphState.save(appState.user.uid);
                history.push(`/graphs/${graphState.id}`);
            }
        } catch (Error) {
            alert('Error saving the object');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            saveGraph();
            setValidated(false);
        } else {
            setValidated(true);
        }
    };

    if ( appState.user ) {
        return (
            <>
            <Form inline className="mr-2" validated={validated} onSubmit={handleSubmit} >
                <Button variant="primary mr-1" onClick={() => history.push('/graphs')}>My Graphs</Button>
                <Button variant="primary mr-1" onClick={() => history.push('/')}>New</Button>
                <Button variant="primary mr-1" type="submit" >Save</Button>
                <Form.Control required onChange={(evt) => graphState.setName(evt.target.value)} value={ graphState.name }/>
            </Form>
            <Button variant="primary" onClick={ () => appState.logout() }>Logout</Button>
            </>
        );
    }
    return (
        <>
        <Form inline>
            <Button variant="primary mr-1" onClick={ () => appState.openLoginModal() } >Save</Button>
        </Form>
        <Button variant="primary" onClick={ () => appState.openLoginModal() }>Login</Button>
        </>
    );
});

export default SessionButtons;
