import React, { useEffect } from 'react';
import { graphviz } from 'd3-graphviz';


const defaultGraphvizOptions = {
    fit: true,
    height: '90%',
    width: '100%',
    zoom: true,
};

const GraphvizRenderer = ({ id, className, dot, options, onError }) => {

    useEffect(() => {
        window.graphviz = graphviz;
        try {
            graphviz(`#${ id }`)
                .options({
                    ...defaultGraphvizOptions,
                    ...options || {}
                })
                .onerror(function (error) {
                    if (onError) {
                        onError(error);
                    }
                }).renderDot(dot);
        } catch(error) {
            console.log('Node error!');
        }
    }, [id, dot, options, onError]);

    return (
        <div id={ id } className={ className }></div>
    );
};

export default GraphvizRenderer;
