import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from "react-router-dom";
import { GraphState } from '../../States';
import { NewPage } from '../';
import { Loading } from '../../Components';

const EditPageView = observer(
    class EditPage extends Component {

        constructor(props) {
            super(props);
            this.graphState = new GraphState(props.db);
            this.state = {
                loading: false
            };
        }

        async loadGraph(graphId) {
            try {
                if (graphId) {
                    this.setState({loading: true});
                    await this.graphState.loadGraph(graphId);
                    this.setState({loading: false});
                } else {
                    throw new Error('graphId not provided!');
                }
            } catch(Error) {
                this.props.history.replace('/');
            }
        }

        componentWillReceiveProps(newProps) {
            let graphId = newProps.match.params.id;
            if (graphId === this.graphState.id) {
                return;
            }
            this.loadGraph(graphId);
        }

        componentDidMount() {
            let graphId = this.props.match.params.id;
            this.loadGraph(graphId);
        }

        render() {
            if (this.state.loading === true) {
                return (<Loading />);
            }
            return (
                <NewPage {...this.props} graphState={this.graphState} />
            );
        }
    });

export default (props) => (<EditPageView {...props} history={useHistory()} />);
