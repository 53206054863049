import React from 'react';
import * as firebaseui from 'firebaseui';
import { Modal } from 'react-bootstrap';


const AuthModal = ({ show, hide, firebase }) => {

  const onShow = () => {
    setTimeout(() => {
      var ui = firebaseui.auth.AuthUI.getInstance();

      if (!ui) {
        ui = new firebaseui.auth.AuthUI(firebase.auth());
      }

      ui.start('#firebase-ui-auth', {
        callbacks: {
          signInSuccessWithAuthResult: () => false
        },
        signInFlow: 'popup',
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.GithubAuthProvider.PROVIDER_ID
        ],
      });
    }, 1000);
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      keyboard={false}
      animation={false}
      onShow={onShow}
    >
      <Modal.Header closeButton>
        <Modal.Title>Login/Signup</Modal.Title>
      </Modal.Header>
      <Modal.Body id="firebase-ui-auth">
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
