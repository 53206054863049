import React, { Component } from 'react'
import { observer, Provider } from 'mobx-react';
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router, Route } from 'react-router';
import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { AuthModal } from './Components';
import { NewPage, EditPage, ListPage } from './Pages';
import { FIREBASE_CONFIG } from './config';
import { AppState } from './States';
import { Alert } from 'react-bootstrap';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const stores = {
    routing: routingStore
}
const history = syncHistoryWithStore(browserHistory, routingStore);

firebase.initializeApp(FIREBASE_CONFIG);
const db = firebase.firestore();

const AppView = observer(
    class App extends Component {

        componentDidMount () {
            firebase.auth().onAuthStateChanged((user, error) => {
                this.setState({user: user});
                this.props.appState.closeLoginModal();
                this.props.appState.login(user);
                if (user) {
                    localStorage.setItem('expectSignIn', '1');
                } else {
                    localStorage.removeItem('expectSignIn');
                }
            });
        }

        render () {
            return (
                <Provider {...stores}>
                    <Router history={history}>
                        <AuthModal
                        show={ this.props.appState.showLoginModal }
                        hide={ () => this.props.appState.closeLoginModal() }
                        firebase={firebase}
                        />
                        <Route exact path={'/'}>
                            <NewPage
                            appState={ this.props.appState }
                            db={ db }
                            />
                        </Route>
                        <Route exact path={'/graphs'} render={props => (<ListPage {...props} appState={ this.props.appState } db={ db } />)} />
                        <Route path={'/graphs/:id'} render={props => (<EditPage {...props} appState={ this.props.appState } db={ db } />)} />
                    </Router>
                      <Alert variant="info" className="fixed-bottom w-25 information-alert">
                        We support {' '}
                        <Alert.Link href="https://www.worthe-it.co.za/blog/2017-09-19-quick-introduction-to-graphviz.html" target="blank">Graphviz</Alert.Link>
                        {' '} and {' '}
                        <Alert.Link href="https://mermaid-js.github.io/mermaid/" target="blank">Mermaid</Alert.Link>
                      </Alert>
                </Provider>
            );
        }
    }
);

export default () => <AppView appState={new AppState(firebase)} />;
