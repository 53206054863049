export default (RedPanda) => {

    const GraphDocument = RedPanda.create('graphs', {
        name: RedPanda.types.string().required(),
        code: RedPanda.types.string().required(),
        userId: RedPanda.types.string().required()
    }, false, 'graphs');

    return {
        GraphDocument
    };
};
