import { makeAutoObservable } from 'mobx';
import mermaid from 'mermaid';
import { graphviz } from 'd3-graphviz';
import { Models } from '../Models';

class Graph {
    id = null;
    name = `NewGraph`;
    code = 'digraph {\n a -> b\n}';
    userId = null;

    constructor(db) {
        makeAutoObservable(this)
        this.models = Models(db);
    }

    setName(name) {
        this.name = name;
    }

    setCode(code) {
        if (this.isGraphviz(code) || this.isMermaid(code)) {
            this.code = code;
        }
    }

    setLoading(loading) {
        this.loading = loading;
    }

    setId(id) {
        this.id = id;
    }

    setUserId(userId) {
        this.userId = userId;
    }

    async save(userId) {
        let graph = new this.models.Graph({
            name: this.name,
            code: this.code,
            userId: userId
        });
        await graph.save();
        this.setId(graph.id);
        this.setUserId(graph.userId);
    }

    async update() {
        await this.graph.update({name: this.name, code: this.code});
    }

    async loadGraph(id) {
        let graph = await this.models.Graph.findByID(id);
        if (!graph) {
            throw new Error('Graph not found!');
        }
        this.graph = graph;
        this.setName(graph.name);
        this.setCode(graph.code);
        this.setUserId(graph.userId);
        this.setId(graph.id);
    }

    isGraphviz(code = this.code) {
        let result = true;
        try {
            let virtualElement = document.createElement('div');
            graphviz(virtualElement).dot(code);
            virtualElement.remove();
        } catch(error) {
            result = false;
        }
        return result;
    }

    isMermaid(code = this.code) {
        let result = true;
        try {
            mermaid.parse(code);
        } catch(error) {
            result = false;
        }
        return result;
    }

    async deleteGraph() {
        if ( !this.graph ) {
            await this.loadGraph(this.id);
        }
        await this.graph.delete();
    }
}

export default Graph;
